import axios from 'axios';

const logout = async () => {
  const NEXT_PUBLIC_BACKEND_HTTP_URL = process.env
    .NEXT_PUBLIC_BACKEND_HTTP_URL as string;

  await axios.get(NEXT_PUBLIC_BACKEND_HTTP_URL + '/dashboard/auth/v1/logout', {
    withCredentials: true,
  });
  if (process.env.NEXT_PUBLIC_WICKED_HTTP_URL) {
    window.location.href =
      String(process.env.NEXT_PUBLIC_WICKED_HTTP_URL) + '/login/logout';
  }
};

export default logout;

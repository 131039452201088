import React from 'react';

import { useReactiveVar } from '@apollo/client';
import styled from '@emotion/styled';
import { Divider } from 'antd';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Avatar from 'react-avatar';

import AllAppsIcon from '@assets/customizedIconSet/Apps.svg';
// import Bell from '@assets/customizedIconSet/Bell.svg';
import Tooltip from '@components/elements/Tooltip';
import { userVar } from 'src/apollo/reactiveVars';

import NotificationDrawer from '../NotificationDrawer';
import ProfileDrawer from '../ProfileDrawer';

const StyledDiv = styled.div`
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.7);
  background-color: ${(props) => props.theme.color.primary800};

  .part-top {
    width: 4.5rem;
    display: flex;
    flex-direction: column;
    color: ${(props) => props.theme.color.gray700};
    background-color: ${(props) => props.theme.color.primary800};

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5rem;
      width: 100%;
      height: 4.5rem;
      cursor: default;

      svg {
        color: ${(props) => props.theme.sidebar.appIcon};
        width: 2.5rem;
        height: 2.5rem;
      }

      &.app-icon {
        svg {
          width: 3rem;
          height: 3rem;
          fill: ${(props) => props.theme.sidebar.appIcon};
        }
      }
    }
  }

  nav {
    ul {
      .menuitem {
        list-style-type: none;
        height: 4.25rem;

        button {
          width: 100%;
          height: 100%;
          color: ${(props) => props.theme.color.gray700};
          background: transparent;
          outline: none;
          border: none;
          cursor: pointer;
          border-left: 0.25em solid transparent;
          border-right: 0.25em solid transparent;

          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            width: 2.2rem;
            height: 2.2rem;
            fill: currentColor;
          }
        }
        &:not(.currently-selected-tab) {
          &:hover {
            background-color: ${(props) => props.theme.color.primary400};
            color: ${(props) => props.theme.color.gray500};
          }
        }

        &.currently-selected-tab button {
          background-color: ${(props) => props.theme.color.primary100};
          border-left: 0.25em solid ${(props) => props.theme.color.accent700};
          color: ${(props) => props.theme.color.gray200};

          &:hover {
            background-color: ${(props) =>
              props.theme.color.primary100} !important;
            color: ${(props) => props.theme.color.gray200} !important;
          }
        }
      }
    }
  }

  .apps-collapsed-btn {
    background-color: ${(props) => props.theme.color.primary800};
    color: ${(props) => props.theme.color.gray700};
    border: none;
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.2em 1em;
    width: 100%;
    cursor: pointer;
    height: 4em;
    &:first-of-type {
      height: 4.5em;
    }
  }
  .part-bottom {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .apps-icon {
      margin-bottom: 1rem;
      cursor: pointer;
    }
  }
  .divider-container {
    width: 100%;
    padding: 0.1em 0.3em;
  }
  .divider-container .ant-divider-horizontal {
    margin: 0;
    // background: ${(props) => props.theme.color.gray700};
    background: ${(props) => props.theme.color.gray900};
  }
`;

// const StyledBell = styled(Bell)`
//   fill: ${(props) => props.theme.color.gray300};
// `;

const StyledAllAppsIcon = styled(AllAppsIcon)`
  height: 1.6em;
  width: 1.6em;
  fill: ${(props) => props.theme.color.gray700};
`;

interface Tab {
  id: string;
  title: string;
  icon: JSX.Element;
  onClick(): void;
}
interface SidebarProps {
  logo: JSX.Element;
  appIcon?: JSX.Element;
  tabs: Tab[];
}

function Sidebar(props: {
  sidebarProps: SidebarProps;
  className?: string;
}): JSX.Element {
  // Get User
  const user = useReactiveVar(userVar);

  const router = useRouter();
  const currentTab: string = router.query.tab
    ? `${router.query.tab}`
    : props.sidebarProps.tabs[0].id;

  // profile drawer
  const [isProfileDrawerVisible, setIsProfileDrawerVisible] =
    React.useState(false);
  const showProfileDrawer = () => {
    setIsProfileDrawerVisible(true);
  };
  const profileDrawerClose = () => {
    setIsProfileDrawerVisible(false);
  };

  // notifications drawer
  const [isNotificationsDrawerVisible, setIsNotificationsDrawerVisible] =
    React.useState(false);
  // const showNotificationsDrawer = () => {
  //   setIsNotificationsDrawerVisible(true);
  // };
  const notificationsDrawerClose = () => {
    setIsNotificationsDrawerVisible(false);
  };

  const [currentlySelectedTab, setCurrentlySelectedTab] = React.useState('');

  if (currentlySelectedTab === '' || currentlySelectedTab !== currentTab) {
    setCurrentlySelectedTab(currentTab);
  }

  return (
    <StyledDiv>
      <div className="part-top">
        <div className="icon logo">{props.sidebarProps.logo}</div>
        {props.sidebarProps.appIcon && (
          <div className="icon app-icon">{props.sidebarProps.appIcon}</div>
        )}
        <nav>
          <ul>
            {props.sidebarProps.tabs.map((tab: Tab) => (
              <li
                className={`menuitem ${
                  currentTab === tab.id ? 'currently-selected-tab' : ''
                }`}
                key={tab.title}
              >
                <Tooltip title={tab.title} placement="right">
                  <button onClick={tab.onClick}>{tab.icon}</button>
                </Tooltip>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <div className="part-bottom">
        <Link href="/" passHref shallow>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a>
            <StyledAllAppsIcon className="apps-icon" />
          </a>
        </Link>

        <div className={'divider-container'}>
          <Divider className={'divider'} />
        </div>

        {/* <button
          className="apps-collapsed-btn"
          onClick={() => {
            showNotificationsDrawer();
            console.log('preferences clicked');
          }}
          onKeyDown={() => showNotificationsDrawer()}
        >
          <StyledBell className="apps-icon" />
        </button> */}
        <button
          className="apps-collapsed-btn"
          onClick={() => showProfileDrawer()}
        >
          <div className={'avatar'}>
            <Avatar
              name={user?.name}
              size="2.2em"
              textSizeRatio={2.2}
              round={true}
              maxInitials={2}
            />
          </div>
        </button>
      </div>
      <NotificationDrawer
        isNotificationsDrawerVisible={isNotificationsDrawerVisible}
        notificationsDrawerClose={notificationsDrawerClose}
        closable={false}
      />
      <ProfileDrawer
        isProfileDrawerVisible={isProfileDrawerVisible}
        profileDrawerClose={profileDrawerClose}
      />
    </StyledDiv>
  );
}

export default Sidebar;

import { JobStatus } from 'src/global/constants';
export interface SortOption {
  value: string;
  label: string;
}

// filterParameter
export const bySortOptions: SortOption[] = [
  { value: 'createdAt', label: 'Created Time' },
  { value: 'updatedAt', label: 'Updated Time' },
];

// filterValue
export const timeSortOptions: SortOption[] = [
  { value: '7-days', label: 'Last 7 Days' },
  { value: '1-month', label: 'Last 1 Month' },
  { value: '3-month', label: 'Last 3 Months' },
  { value: '6-month', label: 'Last 6 Months' },
];

export const initialFilters = {
  bySortFilterParameter: {
    value: bySortOptions[0].value,
    label: bySortOptions[0].label,
  },
  timeSortFilterParameter: {
    value: timeSortOptions[0].value,
    label: timeSortOptions[0].label,
  },
  searchValue: '',
  activeTab: JobStatus.all,
  pageNumber: 1,
};

import { ReactNode } from 'react';

import { useTheme } from '@emotion/react';
import { Tooltip as AntdTooltip } from 'antd';

interface TooltipType {
  title: ReactNode;
  children: any;
  color?: string;
  mouseEnterDelay?: number;
  trigger?: 'hover' | 'focus' | 'click' | 'contextMenu' | string[];
  placement?:
    | 'top'
    | 'left'
    | 'right'
    | 'bottom'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
    | 'leftTop'
    | 'leftBottom'
    | 'rightTop'
    | 'rightBottom';
}

const Tooltip = (props: TooltipType) => {
  const theme = useTheme();
  return (
    <AntdTooltip
      title={props.title}
      color={props.color || theme.color.primary200}
      mouseEnterDelay={props.mouseEnterDelay || 0.3}
      trigger={props.trigger || 'hover'}
      placement={props.placement || 'top'}
    >
      {props.children}
    </AntdTooltip>
  );
};

export default Tooltip;
